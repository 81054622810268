<template>
  <b-card no-body>
    <b-card-header class="p-1">
      <h4 class="mb-0">Student Attendance</h4>
      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr
          v-model="attDate"
          :config="config2"
          class="form-control flat-picker bg-transparent border-0 shadow-none cursor-pointer"
          placeholder="Select date"
          @on-change="LoadData()"
        />
      </div>
    </b-card-header>
    <b-card-body class="px-1 pb-1">
      <b-row>
        <b-col
          lg="10"
          md="9"
          class="d-flex flex-wrap order-1 order-lg-0"
          style="gap: 1rem"
        >
          <div>
            <div class="mb-25">
              <span class="font-weight-bold">Total: </span>
              <b-badge variant="light-primary">
                {{ attSummary.stdTotal }}
              </b-badge>
            </div>
          </div>
          <div>
            <div class="mb-25">
              <span class="font-weight-bold">P: </span>
              <b-badge variant="light-primary">
                {{ attSummary.stdPTotal }}
              </b-badge>
            </div>
          </div>
          <div>
            <div class="mb-25">
              <span class="font-weight-bold">A: </span>
              <b-badge variant="light-primary">
                {{ attSummary.stdATotal }}
              </b-badge>
            </div>
          </div>
          <div>
            <div class="mb-25">
              <span class="font-weight-bold">L: </span>
              <b-badge variant="light-primary">
                {{ attSummary.stdLvTotal }}
              </b-badge>
            </div>
          </div>
          <div>
            <div class="mb-25">
              <span class="font-weight-bold">Late: </span>
              <b-badge variant="light-primary">
                {{ attSummary.stdLtTotal }}
              </b-badge>
            </div>
          </div>
        </b-col>
        <b-col lg="2" md="3" class="text-right">
          <b-button
            variant="primary"
            class="btn-icon"
            v-b-tooltip.hover.top
            title="Export"
            @click="exportData()"
            :disabled="attLoading || exporting"
          >
            <b-spinner v-if="exporting" small />
            <feather-icon v-else icon="DownloadIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <b-table
      class=""
      sticky-header="350px"
      show-empty
      :items="attItems"
      :fields="attFields"
      responsive
      hover
      :busy="attLoading"
    >
      <template #table-busy>
        <div
          class="d-flex justify-content-center mb-3"
          style="margin-top: 50px"
        >
          <b-spinner
            style="width: 3rem; height: 3rem"
            type="grow"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </div>
      </template>

      <template #cell(className)="data">
        <b-badge variant="light-primary">
          {{ data.value }}
        </b-badge>
      </template>
      <template #cell(total)="data">
        <b-badge variant="light-primary">
          {{ data.value }}
        </b-badge>
      </template>
      <template #cell(present)="data">
        <b-badge variant="light-success" class="mr-25">
          {{ data.value }}
        </b-badge>
      </template>
      <template #cell(absent)="data">
        <b-badge variant="light-danger">
          {{ data.value }}
        </b-badge>
      </template>
      <template #cell(leave)="data">
        <b-badge variant="light-info">
          {{ data.value }}
        </b-badge>
      </template>
      <template #cell(late)="data">
        <b-badge variant="light-warning">
          {{ data.value }}
        </b-badge>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BTable,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormGroup,
  VBTooltip,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import * as XLSX from "xlsx";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "AttendanceCard",
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BTable,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    flatPickr,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      attDate: new Date().toJSON().split("T")[0],
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      attSummary: {
        stdTotal: 0,
        stdPTotal: 0,
        stdATotal: 0,
        stdLvTotal: 0,
        stdLtTotal: 0,
      },
      attLoading: false,
      attItems: [],
      attFields: [
        { label: "class", key: "className" },
        { label: "students", key: "total" },
        { label: "present", key: "present" },
        { label: "absent", key: "absent" },
        { label: "leave", key: "leave" },
        { label: "late", key: "late" },
      ],
      exporting: false,
    };
  },
  created() {
    this.LoadData();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    exportData() {
      this.exporting = true;

      const expData = this.attItems.map((item) => ({
        class: item.className,
        students: item.total,
        present: item.present,
        absent: item.absent,
        leave: item.leave,
        late: item.late,
      }));

      // Add empty row and totals
      expData.push({
        class: "",
        students: "",
        present: "",
        absent: "",
        leave: "",
        late: "",
      });
      expData.push({
        class: "Total",
        students: this.attSummary.stdTotal,
        present: this.attSummary.stdPTotal,
        absent: this.attSummary.stdATotal,
        leave: this.attSummary.stdLvTotal,
        late: this.attSummary.stdLtTotal,
      });

      const filename = `attendace ${this.attDate}.xlsx`;
      const worksheet = XLSX.utils.json_to_sheet(expData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, filename);
      this.exporting = false;
    },
    async LoadData() {
      if (this.attDate) {
        this.attLoading = true;

        var obj = {
          url:
            this.$store.state.domain +
            "Users/DashboardClasswiseAttendance?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&dt=" +
            this.attDate,
          token: this.$store.state.userData.token,
          dashboard: true,
        };
        let res = await this.get(obj);
        // console.log(res);
        this.attItems = res.data;
        this.attSummary = {
          stdTotal: res.stdTotal,
          stdPTotal: res.stdPTotal,
          stdATotal: res.stdATotal,
          stdLvTotal: res.stdLvTotal,
          stdLtTotal: res.stdLtTotal,
        };
        this.attLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
