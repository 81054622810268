<template>
  <section id="dashboard-ecommerce">
    <div v-if="loading">
      <b-row align-h="center" v-if="loading">
        <b-spinner
          class="m-5"
          style="width: 4rem; height: 4rem"
          type="grow"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-row>
    </div>
    <div v-else>
      <statistics :data="dash" :otherData="otherData" />

      <!-- <b-row class="match-height">
        <b-col lg="4" md="4">
          <ecommerce-earnings-chart
            :data="dash"
            :monthlyData="monthlyData"
            :monthlySeries="monthlySeries"
          />
        </b-col>
        <b-col lg="8" md="8">
          <ecommerce-statistics :data="dash" :otherData="otherData" />
        </b-col>
      </b-row> -->

      <b-row class="match-height">
        <b-col lg="7" md="12">
          <b-card no-body>
            <b-card-header>
              <b-card-title>Outstanding Dues</b-card-title>
            </b-card-header>
            <b-table
              class=""
              sticky-header="350px"
              :busy="feeLoading"
              :items="feeItems"
              :fields="feeFields"
              show-empty
              responsive
              hover
            >
              <template #table-busy>
                <div class="d-flex justify-content-center my-3">
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>

              <template #cell(feeType)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(students)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(amount)="data">
                <b-badge variant="light-primary"
                  >Rs. {{ data.value.toLocaleString() }}</b-badge
                >
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col lg="5" md="12">
          <fee-bar-chart :items="feeItems" />
        </b-col>
      </b-row>

      <template
        v-if="reportDomain == 'myskoolhpgs' && $store.state.userData.cId == 1"
      >
        <b-card no-body>
          <b-card-header class="p-1">
            <div>
              <b-card-title>Student Statistics</b-card-title>
            </div>
            <div class="d-flex align-items-center">
              <feather-icon icon="CalendarIcon" size="16" />
              <flat-pickr
                v-model="rangeDate"
                :config="config"
                class="form-control flat-picker bg-transparent border-0 shadow-none cursor-pointer"
                placeholder="Select date"
                @on-change="setDate"
              />
            </div>
          </b-card-header>

          <b-card-body class="px-1 pb-1">
            <b-row>
              <b-col
                lg="7"
                md="12"
                class="d-flex flex-wrap order-1 order-lg-0"
                style="gap: 1rem"
              >
                <div>
                  <div class="mb-25">
                    <span class="font-weight-bold">Inquiries: </span>
                    <b-badge variant="light-primary">
                      {{ summaryObj.inqTotal }}
                    </b-badge>
                  </div>
                  <div>
                    <span class="font-weight-bold">Completed: </span>
                    <b-badge variant="light-primary">
                      {{ summaryObj.inqCompTotal }}
                    </b-badge>
                  </div>
                </div>
                <div>
                  <div class="mb-25">
                    <span class="font-weight-bold">Registrations: </span>
                    <b-badge variant="light-primary">
                      {{ summaryObj.regTotal }}
                    </b-badge>
                  </div>
                  <div>
                    <span class="font-weight-bold">Pending: </span>
                    <b-badge variant="light-primary">
                      {{ summaryObj.regPendingTotal }}
                    </b-badge>
                  </div>
                </div>
                <div>
                  <div class="mb-25">
                    <span class="font-weight-bold">Admissions: </span>
                    <b-badge variant="light-primary">
                      {{ summaryObj.admTotal }}
                    </b-badge>
                  </div>
                  <div>
                    <span class="font-weight-bold">Pending: </span>
                    <b-badge variant="light-primary">
                      {{ summaryObj.admPendingTotal }}
                    </b-badge>
                  </div>
                </div>
                <div>
                  <span class="font-weight-bold">Amount: </span>
                  <b-badge variant="light-primary">
                    {{ summaryObj.amountTotal }}
                  </b-badge>
                </div>
              </b-col>
              <b-col
                lg="5"
                md="12"
                class="d-flex align-items-center justify-content-between pl-lg-0 mb-1 mb-lg-0"
              >
                <b-form-group class="flex-grow-1 pr-50 mb-0">
                  <v-select
                    multiple
                    :closeOnSelect="false"
                    v-model="campIDs"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="[{ id: 0, branch: 'All Campuses' }, ...campuses]"
                    :clearable="false"
                    :reduce="(val) => val.id"
                    label="branch"
                    placeholder="Select campus"
                    @input="setCamp()"
                  />
                </b-form-group>
                <b-button
                  variant="primary"
                  class="btn-icon"
                  :disabled="stLoading"
                  @click="LoadSummary()"
                  >show</b-button
                >
              </b-col>
            </b-row>
          </b-card-body>
          <b-table
            class=""
            sticky-header="650px"
            :busy="stLoading"
            :items="items"
            :fields="fields"
            show-empty
            responsive
            hover
          >
            <template #table-busy>
              <div class="d-flex justify-content-center my-3">
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>

            <template #head(inquiries)="data">
              <div class="text-center">
                {{ data.label }}
                <div class="font-small-1 text-center">Total | Completed</div>
              </div>
            </template>
            <template #head(registrations)="data">
              <div class="text-center">
                {{ data.label }}
                <div class="font-small-1 text-center">Total | Pending</div>
              </div>
            </template>
            <template #head(admissions)="data">
              <div class="text-center">
                {{ data.label }}
                <div class="font-small-1 text-center">Total | Pending</div>
              </div>
            </template>

            <template #cell(campus)="data">
              <b-badge variant="light-primary">
                {{ data.item.campusName }}
              </b-badge>
            </template>
            <template #cell(inquiries)="data">
              <div class="text-center">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
                |
                <b-badge variant="light-success">
                  {{ data.item.inquiriesCompleted }}
                </b-badge>
              </div>
            </template>
            <template #cell(registrations)="data">
              <div class="text-center">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
                |
                <b-badge variant="light-danger">
                  {{ data.item.registrationPending }}
                </b-badge>
              </div>
            </template>
            <template #cell(admissions)="data">
              <div class="text-center">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
                |
                <b-badge variant="light-danger">
                  {{ data.item.admissionPending }}
                </b-badge>
              </div>
            </template>
            <template #cell(amount)="data">
              <b-badge variant="light-primary"> Rs. {{ data.value }} </b-badge>
            </template>
            <template #cell(actions)="data">
              <b-button
                variant="outline-primary"
                class="btn-icon"
                @click="openReport(data.item.campusID)"
              >
                <feather-icon icon="PrinterIcon" />
              </b-button>
            </template>
            <!-- <template #custom-foot>
              <tr
                class="position-sticky"
                style="bottom: 0; background: #f3f2f7"
              >
                <td>TEST 1</td>
                <td>TEST 2</td>
                <td>TEST 3</td>
                <td>TEST 4</td>
                <td>TEST 5</td>
              </tr>
            </template> -->
          </b-table>
        </b-card>

        <b-card no-body>
          <b-card-header class="p-1">
            <h4 class="mb-0">
              Attendance
              <!-- <b-badge variant="light-success" class="ml-50">
                {{
                  new Date(attDate).toLocaleDateString("en-UK", {
                    year: "numeric",
                    day: "numeric",
                    month: "short",
                  })
                }}
              </b-badge> -->
            </h4>
            <div class="d-flex align-items-center">
              <feather-icon icon="CalendarIcon" size="16" />
              <flat-pickr
                v-model="attDate"
                :config="config2"
                class="form-control flat-picker bg-transparent border-0 shadow-none cursor-pointer"
                placeholder="Select date"
                @on-change="LoadAttendance()"
              />
            </div>
          </b-card-header>
          <b-card-body class="px-1 pb-1">
            <b-row>
              <b-col
                lg="7"
                md="12"
                class="d-flex flex-wrap order-1 order-lg-0"
                style="gap: 1rem"
              >
                <div>
                  <div class="mb-25">
                    <span class="font-weight-bold">Students: </span>
                    <b-badge variant="light-primary">
                      {{ attSummary.stdTotal }}
                    </b-badge>
                  </div>
                  <div>
                    <span class="font-weight-bold">Staff: </span>
                    <b-badge variant="light-primary">
                      {{ attSummary.stfTotal }}
                    </b-badge>
                  </div>
                </div>
                <div>
                  <div class="mb-25">
                    <span class="font-weight-bold">P: </span>
                    <b-badge variant="light-primary">
                      {{ attSummary.stdPTotal }}
                    </b-badge>
                  </div>
                  <div>
                    <span class="font-weight-bold">P: </span>
                    <b-badge variant="light-primary">
                      {{ attSummary.stfPTotal }}
                    </b-badge>
                  </div>
                </div>
                <div>
                  <div class="mb-25">
                    <span class="font-weight-bold">A: </span>
                    <b-badge variant="light-primary">
                      {{ attSummary.stdATotal }}
                    </b-badge>
                  </div>
                  <div>
                    <span class="font-weight-bold">A: </span>
                    <b-badge variant="light-primary">
                      {{ attSummary.stfATotal }}
                    </b-badge>
                  </div>
                </div>
                <div>
                  <div class="mb-25">
                    <span class="font-weight-bold">L: </span>
                    <b-badge variant="light-primary">
                      {{ attSummary.stdLvTotal }}
                    </b-badge>
                  </div>
                  <span class="font-weight-bold">L: </span>
                  <b-badge variant="light-primary">
                    {{ attSummary.stfLvTotal }}
                  </b-badge>
                </div>
                <div>
                  <div class="mb-25">
                    <span class="font-weight-bold">Late: </span>
                    <b-badge variant="light-primary">
                      {{ attSummary.stdLtTotal }}
                    </b-badge>
                  </div>
                  <span class="font-weight-bold">Late: </span>
                  <b-badge variant="light-primary">
                    {{ attSummary.stfLtTotal }}
                  </b-badge>
                </div>
              </b-col>
              <b-col
                lg="5"
                md="12"
                class="d-flex align-items-center justify-content-between pl-lg-0 mb-1 mb-lg-0"
              >
                <b-form-group class="flex-grow-1 pr-50 mb-0">
                  <v-select
                    multiple
                    :closeOnSelect="false"
                    v-model="attCampIDs"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="[{ id: 0, branch: 'All Campuses' }, ...campuses]"
                    :clearable="false"
                    :reduce="(val) => val.id"
                    label="branch"
                    placeholder="Select campus"
                    @input="setAttCamp()"
                  />
                </b-form-group>
                <b-button
                  variant="primary"
                  class="btn-icon"
                  :disabled="attLoading"
                  @click="LoadAttendance()"
                  >show</b-button
                >
              </b-col>
            </b-row>
          </b-card-body>
          <b-table
            class=""
            sticky-header="350px"
            show-empty
            :items="attItems"
            :fields="attFields"
            responsive
            hover
            :busy="attLoading"
          >
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #head(details)="data">
              <div>(P, A, L, Late)</div>
            </template>
            <template #head(st_details)="data">
              <div>(P, A, L, Late)</div>
            </template>

            <template #cell(campus)="data">
              <b-badge variant="light-primary">
                {{ data.item.campusName }}
              </b-badge>
            </template>
            <template #cell(totalStudents)="data">
              <b-badge variant="light-primary">
                {{ data.item.studentCount }}
              </b-badge>
            </template>
            <template #cell(details)="data">
              <div class="d-flex">
                <b-badge variant="light-success" class="mr-25">
                  {{ data.item.stdP }}
                </b-badge>
                <b-badge variant="light-danger" class="mr-25">
                  {{ data.item.stdAb }}
                </b-badge>
                <b-badge variant="light-info" class="mr-25">
                  {{ data.item.stdLv }}
                </b-badge>
                <b-badge variant="light-warning">
                  {{ data.item.stdLt }}
                </b-badge>
              </div>
            </template>
            <template #cell(st_total)="data">
              <b-badge variant="light-primary">
                {{ data.item.staffCount }}
              </b-badge>
            </template>
            <template #cell(st_details)="data">
              <div class="d-flex">
                <b-badge variant="light-success" class="mr-25">
                  {{ data.item.staffP }}
                </b-badge>
                <b-badge variant="light-danger" class="mr-25">
                  {{ data.item.staffAb }}
                </b-badge>
                <b-badge variant="light-info" class="mr-25">
                  {{ data.item.staffLv }}
                </b-badge>
                <b-badge variant="light-warning">
                  {{ data.item.staffLt }}
                </b-badge>
              </div>
            </template>
            <template #cell(present)="data">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(absent)="data">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(leave)="data">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(late)="data">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
            </template>
          </b-table>
        </b-card>
      </template>

      <template v-else-if="reportDomain != 'myskoolhpgs'">
        <AttendanceCard />
      </template>

      <b-row class="match-height" v-if="showCollection && showDues">
        <b-col lg="6" md="12" cols="12">
          <chartjs-bar-chart :weeklyData="weeklyData" />
        </b-col>
        <!-- <b-col lg="6" md="12" cols="12">
          <chartjs-line-chart :yearlyData="yearlyData" />
        </b-col> -->
        <b-col lg="6" md="12" cols="12">
          <chartjs-line-area-chart :yearlyData="yearlyData" />
        </b-col>
      </b-row>
    </div>

    <!-- <b-row class="match-height">
      <b-col lg="12">
        <b-row class="match-height">
          <b-col lg="4" md="4">
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <b-col lg="4" md="4">
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col lg="6">
            <ecommerce-revenue-report :data="data.revenue" />
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="8">
        <ecommerce-company-table :table-data="data.companyTable" />
      </b-col>

      <b-col lg="4" md="6">
        <ecommerce-meetup :data="data.meetup" />
      </b-col>

      <b-col lg="4" md="6">
        <ecommerce-browser-states />
      </b-col>

      <b-col lg="4" md="6">
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col>

      <b-col lg="4" md="6">
        <ecommerce-transactions :data="data.transactionData" />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BTable,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormGroup,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import { getUserData } from "@/auth/utils";
import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import EcommerceMeetup from "./EcommerceMeetup.vue";
import EcommerceBrowserStates from "./EcommerceBrowserStates.vue";
import EcommerceGoalOverview from "./EcommerceGoalOverview.vue";
import EcommerceTransactions from "./EcommerceTransactions.vue";
import ChartjsBarChart from "@/views/charts-and-maps/charts/chartjs/ChartjsBarChart.vue";
import ChartjsLineChart from "@/views/charts-and-maps/charts/chartjs/ChartjsLineChart.vue";
import ChartjsLineAreaChart from "@/views/charts-and-maps/charts/chartjs/ChartjsLineAreaChart.vue";
import FeeBarChart from "./FeeBarChart.vue";
import AttendanceCard from "./AttendanceCard.vue";
import Statistics from "./statistics.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    flatPickr,
    vSelect,
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BTable,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    ChartjsLineChart,
    ChartjsLineAreaChart,
    ChartjsBarChart,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    FeeBarChart,
    AttendanceCard,
    Statistics,
  },
  data() {
    return {
      data: {},
      dash: {},
      otherData: {},
      monthlyData: {},
      monthlySeries: [],
      weeklyData: {},
      yearlyData: {},
      loading: true,
      showDues: false,
      showCollection: false,
      currentYear: "",
      rights: {},
      stLoading: false,
      items: [],
      fields: [
        { label: "campus", key: "campus" },
        { label: "inquiries", key: "inquiries" },
        { label: "registrations", key: "registrations" },
        { label: "admissions", key: "admissions" },
        { label: "amount", key: "amount" },
        { label: "actions", key: "actions" },
      ],
      attLoading: false,
      attItems: [],
      attFields: [
        { label: "campus", key: "campus" },
        { label: "students", key: "totalStudents" },
        { label: "details", key: "details" },
        { label: "staff", key: "st_total" },
        { label: "st_details", key: "st_details" },
      ],
      feeLoading: false,
      feeItems: [],
      feeFields: [
        { label: "feetype", key: "feeType" },
        { label: "students", key: "students" },
        { label: "amount", key: "amount" },
      ],
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      config: {
        mode: "range",
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      dateObj: {
        dtFrom: null,
        dtTo: null,
      },
      rangeDate: "",
      attDate: null,
      summaryObj: {
        inqTotal: 0,
        inqCompTotal: 0,
        amountTotal: 0,
        admPendingTotal: 0,
        admTotal: 0,
        regTotal: 0,
        regPendingTotal: 0,
      },
      lastLoad: {
        from: null,
        to: null,
      },
      campuses: [],
      campIDs: [0],
      attCampIDs: [0],
      attSummary: {
        stdTotal: 0,
        stdPTotal: 0,
        stdATotal: 0,
        stdLvTotal: 0,
        stdLtTotal: 0,
        stfTotal: 0,
        stfPTotal: 0,
        stfATotal: 0,
        stfLvTotal: 0,
        stfLtTotal: 0,
      },
      quickStats: {
        collection: 125000,
        receipts: 45,
        students: 2450,
        staff: 120,
        inquiries: 15,
        dues: 75000,
      },
    };
  },
  created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    // !this.$store.state.rights[this.$route.name]
    if (false) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      // console.log(this.rights);
      this.currentYear = new Date().getFullYear();
      this.LoadData();

      // const fd = new Date();
      // this.dateObj.dtFrom = `${fd.getFullYear()}-${(fd.getMonth() + 1)
      //   .toString()
      //   .padStart(2, "0")}-01`;

      // const ld = new Date(fd.getFullYear(), fd.getMonth() + 1, 0);
      // this.dateObj.dtTo = `${ld.getFullYear()}-${(ld.getMonth() + 1)
      //   .toString()
      //   .padStart(2, "0")}-${ld.getDate().toString().padStart(2, "0")}`;

      const dt = new Date().toJSON().split("T")[0];
      this.attDate = dt;
      this.dateObj.dtFrom = dt;
      this.dateObj.dtTo = dt;
      this.lastLoad.from = dt;
      this.lastLoad.to = dt;
      this.rangeDate = `${this.dateObj.dtFrom} to ${this.dateObj.dtTo}`;
      if (
        this.reportDomain == "myskoolhpgs" &&
        this.$store.state.userData.cId == 1
      ) {
        this.LoadCampuses();
        this.LoadAttendance();
        this.LoadSummary();
      }
      this.LoadCollection();
      this.LoadDues();
      this.$http.get("/ecommerce/data").then((response) => {
        this.data = response.data;
        // console.log(this.data);

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData();
        this.data.congratulations.name =
          userData.fullName.split(" ")[0] || userData.username;
      });
    }
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    setCamp() {
      if (this.campIDs.at(-1) == 0) {
        this.campIDs = [0];
      } else {
        this.campIDs = this.campIDs.filter((el) => el != 0);
      }
    },
    setAttCamp() {
      if (this.attCampIDs.at(-1) == 0) {
        this.attCampIDs = [0];
      } else {
        this.attCampIDs = this.attCampIDs.filter((el) => el != 0);
      }
    },
    openReport(id) {
      // console.log(this.lastLoad);
      var url =
        `https://${this.reportDomain}.myskool.app/Fee/Show?type=dcr&dtFrom=${this.lastLoad.from}&dtTo=${this.lastLoad.to}&dbb=` +
        this.$store.state.userData.db +
        "&cID=" +
        id +
        "&month=&feeType=&format=Cashlog";
      window.open(url, "_blank");
    },
    async LoadData() {
      this.loading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Users/Dashboard?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&year=" +
          this.currentYear,
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      // console.log("dash", res);
      this.dash = res.data;
      this.otherData = {
        familyCount: res.familyCount,
        monthly: res.monthly,
      };
      this.feeItems = res.outstandingData;
      // console.log(this.feeItems);
      // if (this.dash.collectionChart) {
      //   this.monthlyData = this.dash.collectionChart;
      //   this.monthlySeries = [
      //     this.dash.collectionChart.collection,
      //     this.dash.collectionChart.dues,
      //   ];
      // }
      this.loading = false;
      // this.setData();
    },
    setDate(sd, dateStr) {
      // console.log(sd, dateStr);
      if (dateStr) {
        if (sd.length > 1) {
          let date = dateStr.split(" to ");
          if (date.length == 1) {
            this.dateObj.dtFrom = date[0];
            this.dateObj.dtTo = date[0];
          } else {
            this.dateObj.dtFrom = date[0];
            this.dateObj.dtTo = date[1];
          }
          this.LoadSummary();
        } else {
          this.dateObj.dtFrom = dateStr;
          this.dateObj.dtTo = null;
        }
      } else {
        this.dateObj.dtFrom = null;
        this.dateObj.dtTo = null;
      }
      // console.log(this.dateObj.dtFrom, this.dateObj.dtTo);
    },
    async LoadSummary() {
      if (this.dateObj.dtFrom && this.dateObj.dtTo) {
        this.stLoading = true;
        let ids =
          this.campIDs.length == 0 || this.campIDs[0] == 0 ? [] : this.campIDs;

        var obj = {
          url:
            this.$store.state.domain +
            "Users/DashboardInquiries?_db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&dtFrom=" +
            this.dateObj.dtFrom +
            "&dtTo=" +
            this.dateObj.dtTo +
            "&campID=" +
            ids,
          token: this.$store.state.userData.token,
          dashboard: true,
        };
        let res = await this.get(obj);
        this.items = res.data;
        this.summaryObj = {
          amountTotal: res.amountTotal,
          regTotal: res.regTotal,
          regPendingTotal: res.regPendingTotal,
          inqTotal: res.inqTotal,
          inqCompTotal: res.inqCompTotal,
          admTotal: res.admTotal,
          admPendingTotal: res.admPendingTotal,
        };
        this.lastLoad = {
          from: this.dateObj.dtFrom,
          to: this.dateObj.dtTo,
        };
        // console.log(this.items);
        this.stLoading = false;
      }
    },
    async LoadAttendance() {
      if (this.attDate) {
        this.attLoading = true;
        let ids =
          this.attCampIDs.length == 0 || this.attCampIDs[0] == 0
            ? []
            : this.attCampIDs;

        var obj = {
          url:
            this.$store.state.domain +
            "Users/DasboardAttendance?_db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&dt=" +
            this.attDate +
            "&campID=" +
            ids,
          token: this.$store.state.userData.token,
          dashboard: true,
        };
        let res = await this.get(obj);
        // console.log(res);
        this.attItems = res.data;
        this.attSummary = {
          stdTotal: res.stdTotal,
          stdPTotal: res.stdPTotal,
          stdATotal: res.stdATotal,
          stdLvTotal: res.stdLvTotal,
          stdLtTotal: res.stdLtTotal,
          stfTotal: res.stfTotal,
          stfPTotal: res.stfPTotal,
          stfATotal: res.stfATotal,
          stfLvTotal: res.stfLvTotal,
          stfLtTotal: res.stfLtTotal,
        };
        // console.log(this.attItems);
        this.attLoading = false;
      }
    },
    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
    },
    async LoadCollection() {
      var obj = {
        url:
          this.$store.state.domain +
          "Users/DashboardCollection?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&year=" +
          this.currentYear,
        token: this.$store.state.userData.token,
      };
      let collData = await this.get(obj);

      // this.monthlyData = collData.collectionChart;
      // this.monthlySeries = [
      //   collData.collectionChart.collection,
      //   collData.collectionChart.dues,
      // ];

      this.weeklyData = {
        data: collData.collectionGraph,
        highestCollection: collData.highestCollection,
      };

      this.showCollection = true;
    },
    async LoadDues() {
      var obj = {
        url:
          this.$store.state.domain +
          "Users/DashboardDues?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&year=" +
          this.currentYear,
        token: this.$store.state.userData.token,
      };
      let duesData = await this.get(obj);
      this.yearlyData = {
        data: duesData.duesGraph,
        highestDues: duesData.highestDues,
      };
      this.showDues = true;
    },

    setData() {
      this.weeklyData = {
        data: this.dash.collectionGraph,
        highestCollection: this.dash.highestCollection,
      };
      this.yearlyData = {
        data: this.dash.duesGraph,
        highestDues: this.dash.highestDues,
      };
      this.monthlyData = this.dash.collectionChart;
      this.monthlySeries = [
        this.dash.collectionChart.collection,
        this.dash.collectionChart.dues,
      ];
      // console.log(this.monthlySeries);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
